import React, {useState,useEffect,useContext } from 'react';
import "./HeaderCMSModals.css";
import SearchBar from '../../SearchBar/SearchBar';
import { clientConfig } from '../../../config';
import HeaderCMSHamburgerMenu from '../../Headers/HeaderCMS/HeaderCMSHamburgerMenu/HeaderCMSHamburgerMenu';
import { headerCMSContext } from "../../Headers/HeaderCMS/HeaderCMS";
import TreatmentsModal from '../TreatmentsModal/TreatmentsModal';
import { tempGetLocale } from '../../../utils/translator';

const TREATMENTS = "treatments";
const SEARCH = "search";
const HAMBURGER = "hamburger";

interface HeaderCMSModalsProps {
  cancelModal: () => void;
}

const HeaderCMSModals: React.FC<HeaderCMSModalsProps> = ({ cancelModal}) => {
  const { currentOpenModal,country,headerData } = useContext(headerCMSContext); // Use the useContext hook to access the context values
  const [showHamburgerMenu,setShowHamburgerMenu] = useState<boolean>(currentOpenModal === HAMBURGER);
   
  useEffect(()=>{
    if(currentOpenModal === HAMBURGER){
      setShowHamburgerMenu(true);
    }
  },[currentOpenModal])

  const onSearchSubmit = (searchQuery: string) => {
    if (searchQuery) {
      //location TopBar because the search is from the header.
      window.location.href = `${clientConfig.MAIN_WEBSITE_URL}/search/${tempGetLocale(country)}?q=${searchQuery}&from=TopBar`;
    }
  };

  const getModalContent = (modalName: string) => {
    if (modalName === TREATMENTS) {
      return ( <TreatmentsModal headerData={headerData} country={country || 'de'}/>)
    } else if (modalName === SEARCH) {
      return (
        <div
          className="search-container"
          onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
        >
          <SearchBar shouldFocusOnRender={true} onSubmit={(searchQuery: string) => onSearchSubmit(searchQuery)} country={country} />
        </div>
      );
    } else if (modalName === HAMBURGER){
      return (
        <>
          <img className={"cancel-icon"} src={require("../../../icons/X.svg").default} alt="X" />
          <HeaderCMSHamburgerMenu show={showHamburgerMenu} />
        </>
      )
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if(showHamburgerMenu){
      setShowHamburgerMenu(false);
      //timeout for hamburger menu animation
      setTimeout(() => {
        cancelModal();
      }, 500);

    } else {
      //no need for animation
      cancelModal();
    }
  };

  return (
    <div
      className={`header-cms-modal-background${currentOpenModal !== "" ? '-show' : ''}`}
      onClick={handleClick}
    >
      {getModalContent(currentOpenModal)}
    </div>
  );
};

export default HeaderCMSModals;
