
import * as http from "../utils/http";
import { 
  CHOOSE_TREATMENT_PAGE_VIEW_EVENT,
  PAID_ORDER_EVENT,
  PAYMENT_PAGE_VIEW_EVENT,
  QUESTIONNAIRE_PAGE_VIEW_EVENT,
  REGISTRATION_COMPLETED_EVENT, 
  SHIPPING_PAGE_VIEW_EVENT,
  SIGN_UP_PAGE_VIEW_EVENT
} from "../utils/consts";

const ALLOWED_EVENTS = [
  QUESTIONNAIRE_PAGE_VIEW_EVENT, 
  CHOOSE_TREATMENT_PAGE_VIEW_EVENT,
  SHIPPING_PAGE_VIEW_EVENT, 
  PAYMENT_PAGE_VIEW_EVENT, 
  SIGN_UP_PAGE_VIEW_EVENT,
  REGISTRATION_COMPLETED_EVENT, 
  PAID_ORDER_EVENT
];

export const track = (eventType: string, eventName: string, additionalData: any) => {
  
  if (!ALLOWED_EVENTS.includes(eventName)) {
    return;
  }
  
  // Debugging the modified object before sending
  http.postToServer(`tracking/events/fbcapi`, {
    trackingData: window?.eventsTracking?.getData(),
    eventName,
    additionalData,

  }, [http.MIDDLEWARES.CUSTOMER_ID, http.MIDDLEWARES.CART_ORDER])

  .then((response: any) =>  {

  })
  .catch((error: any) => {
    window.clientLogger.warn("Social Media Tracker: Failed to send event to the server", {error, eventName})
  })


};

