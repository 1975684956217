import "./SubmitButton.css";
import { TbChevronRight } from "react-icons/tb";
import * as translator from "../../utils/translator";

export default function SubmitButton({
  isEnabledState = true,
  placeholder = "",
  fontsize = "",
  margin = "",
  height = "",
  onSubmit = (e) => {},
  backgroundColor = "",
  width = "",
  padding = "",
  className = "",
  showArrow = false,
  loading = false,
  language = "de",
  minWidth = "",
  id,
  disabled = false
}) {
  return (
    <>
      {loading || disabled ? (
        <div
          id={id}
          className={"sumbitButton-container-loading  " + className}
          style={{
            backgroundColor: backgroundColor,
            width: width,
            padding: padding,
            height,
            margin: margin,
          }}
        >
          <span style={{ fontSize: fontsize }} className="button-placeholder">
            {loading && translator.translate(language, "default", "SubmitButton/loading_text")}
            {disabled && translator.translate(language, "default", "SubmitButton/form_invalid")}
          </span>
        </div>
      ) : (
        <button
          id={id}
          className={`sumbitButton-container ${!isEnabledState && !loading ? "submit-button-disabled": ""}`  + className}
          disabled={!isEnabledState && !loading}
          style={{
            backgroundColor: backgroundColor,
            width: width,
            padding: padding,
            height,
            margin: margin,
            minWidth: minWidth,
          }}
          onClick={onSubmit}
        >
          <span style={{ fontSize: fontsize }} className="button-placeholder">
            {placeholder}
            {showArrow ? (
              <TbChevronRight size={22} style={{ marginLeft: 10 }} />
            ) : null}
          </span>
        </button>
      )}
    </>
  );
}
