import React, { useState } from 'react';

interface CategoriesHamburgerMenuCategoryAccordionProps {
  rootCategory: any;
  setShow: any
  setSelectedMention: any
}

const CategoriesHamburgerMenuCategoryAccordion: React.FC<CategoriesHamburgerMenuCategoryAccordionProps> = ({ rootCategory, setShow, setSelectedMention }) => {
  const [active, setActive] = useState(false);

  const setItemAndClose = (child: any) => {
    setSelectedMention({ [child.mention]: child.title });
    setShow(false)
  }

  const getGetChildCatagory = (child: any) => {
    const settings = JSON.parse(child.settings || null);
    const hasMenuSticker = settings?.categories_menu_sticker;
    return (
      <a onClick={() => setItemAndClose(child)}>
        <span className={`${hasMenuSticker ? "has-menu-sticker" : ""}`}>{child.title}</span>
        <span> &gt;</span>{/* right arrow */}
        {hasMenuSticker && (
          <span className="categories_menu_sticker">
            {/* grern "NEU" in de site */}
            {settings.categories_menu_sticker}
          </span>
        )
        }
      </a>
    )
  }

  return (
    <div className="root-category-accordion">
      <div
        onClick={() => setActive((current) => !current)}
        className={`root-category-accordion-head`}
      >
        <h4 className={`root-category-title${active ? "-active" : ""}`}>{rootCategory.title}</h4>
        <img
          className={`chevron-icon ${active ? "chevron-up" : "chevron-down"}`}
          src={require("../../../icons/Chevron-Down-Green.svg").default}
          alt="Chevron"
        />
      </div>

      {active ?
        <ul className="child-categories">
          {rootCategory.children.map((child: any) => (
            <li key={child.id} className="child-category">
              {getGetChildCatagory(child)}
            </li>
          ))}
        </ul>
        : null}
    </div>
  );
};



export default CategoriesHamburgerMenuCategoryAccordion;
