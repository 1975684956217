import "./PersonalAdderssShipping.css";
import "../../../../core.css";

import OptionCardCategory from "../OptionCardCategory/OptionCardCategory";
import OptionCard from "../OptionCard/OptionCard";
import ShippingForm from "../ShippingForm/ShippingForm";
import * as translator from "../../../../utils/translator";
import { ShippingDataContext } from "../../Shipping";
import { useEffect, useContext } from "react";
import { useCartContext } from "../../../../customHooks/useCart";


const SHOW_COUPON_CONFIRMED = "show_coupon_confirmed";
const SHOW_COUPON_INPUT = "show_coupon_input";
const DISCOUNT_DATA = "discount_data";
const COUPON = "coupon"; // coupon value display in orderWindow
const OTC_COUPON = "otc_coupon"
const SET_LOADING = "set_loading"


const PersonalAdderssShipping = ({
  allowPrescriptionOnly = true,
  updatePrescriptionOnly,
  pageState,
  selected = false,
  onClick,
  params,
  selectedPersonalShippingIndex,
  updateSelectedPersonalShippingIndex,
}) => {

  const {
    language,
    destinationCountry,
    subscriptionDiscount,
    updateDispatch,
    prescriptionOnly,
    isAutoBonus,
    isTestKit,
    limitToPrescriptionOnly,
    catalog,
    allowEPrescriptionOnlyFeature,
    addCannabisIDToCartProductsState,
  } = useContext(ShippingDataContext);


  const { updateCartAndDB } = useCartContext();

  //update coupon bonus related attributes
  const resetBonus = async (isSubscriptionActive) => {
    if (!isAutoBonus) {
      if (isSubscriptionActive) {
        // await updateBonusDBCart(null, null, null, subscriptionDiscount); //dbcall
        await updateCartAndDB({
          bonus_id: null,
          bonus_value: 0,
          auto_bonus: 0,
          bonus_coupon_code: null,
          applied_subscription_discount: isSubscriptionActive ? subscriptionDiscount : 0
        }, addCannabisIDToCartProductsState)
      }
    }
    updateDispatch(SHOW_COUPON_CONFIRMED, false);
    updateDispatch(DISCOUNT_DATA, {});
    updateDispatch(COUPON, 0);
    updateDispatch(OTC_COUPON, 0);
    updateDispatch(SHOW_COUPON_INPUT, true);

  }

  const toggleOffPrescriptionOnlyOption = async () => {
    updateSelectedPersonalShippingIndex(0);
    updatePrescriptionOnly(false);

    // Update DB
    await updateCartAndDB({
      only_prescription: 0
    }, addCannabisIDToCartProductsState)
  }

  useEffect(() => {
    updateDispatch(SET_LOADING, true);
    resetBonus(true)
    if (!allowPrescriptionOnly) {
      toggleOffPrescriptionOnlyOption()
      updateDispatch(SET_LOADING, false)
    }
    updateDispatch(SET_LOADING, false);

  }, [subscriptionDiscount, destinationCountry]) //was set to change when destinationCountry changes but then subscriptionDiscount state didnt update in time


  //by default, the selected treatment IS NOT prescription only
  useEffect(() => {

    //Update DB according to the prescription only state
    updateCartAndDB({only_prescription: prescriptionOnly}, addCannabisIDToCartProductsState);

    if (limitToPrescriptionOnly) {
      updateCartAndDB({only_prescription: 1}, addCannabisIDToCartProductsState);
      updateSelectedPersonalShippingIndex(1);
      updatePrescriptionOnly(true);
    } 

    addCannabisIDToCartProductsState()

  }, [prescriptionOnly,limitToPrescriptionOnly]);


  const isOptionSelected = (key) => {
    if (key === selectedPersonalShippingIndex) {
      return true;
    }

    return false;
  };

  return pageState.serviceOptions ? (
    <div className={"delivery-panel-outer-container" + (selected ? " selected-pm" : "")}>
      {limitToPrescriptionOnly ? null : <OptionCardCategory
        title={translator.translate(language, "shipping", "ShippingForm/main_title")}
        selected={selected}
        onClick={onClick}
        showRadioSelected={!limitToPrescriptionOnly}
      />}

      <div className="delivery-form-personal-address ">
        <div className={"hideable-content" + (selected ? "" : " hidden")}>
          <div className="delivery-options ">
            <p className="delivery-options-title">
              {pageState.serviceOptions["service_options_title"]
                ? pageState.serviceOptions["service_options_title"].value
                : ""}
            </p>

            {
              limitToPrescriptionOnly ? null : (
                <OptionCard
                  isOptionSelected={isOptionSelected(0)}
                  showDescription={isOptionSelected(0)}
                  data={{
                    value: pageState.serviceOptions["service_option_1"].value,
                    description: translator.translate(language, "shipping", "serviceOptions/service_option_1_description"),
                    bold_description: catalog.content_type === "cannabis" ? translator.translate(language, "shipping", "serviceOptions/service_option_1_cannbis_bold_description") : ""
                  }}
                  badgeText={translator.translate(language, "shipping", "serviceOptions/service_option_1_badge_text")}
                  pageState={pageState}
                  onClick={async () => {
                    if (!isOptionSelected(0)) {
                      await toggleOffPrescriptionOnlyOption()
                      await resetBonus(false)
                    }
                  }
                  }
                  option={"service_option_1"}
                />
              )
            }

            {
              !isTestKit ? (
                <>
                  <OptionCard
                    isOptionSelected={isOptionSelected(1)}
                    showDescription={isOptionSelected(1)}
                    data={
                      (catalog.content_type === "cannabis" && allowEPrescriptionOnlyFeature) ?
                        (
                          limitToPrescriptionOnly ? {
                            value: translator.translate(language, "shipping", "serviceOptions/service_options_regular_cannabis_title")
                          } : {
                            value: translator.translate(language, "shipping", "serviceOptions/service_option_2_cannabis_title"),
                            description: translator.translate(language, "shipping", "serviceOptions/service_option_2_cannabis_description"),
                          })

                        :

                        {
                          value: pageState.serviceOptions["service_option_2"].value,
                          description: translator.translate(language, "shipping", "serviceOptions/service_option_2_description").replace(":price", params.price),
                        }
                    }
                    pageState={pageState}
                    onClick={async () => {
                      if (!isOptionSelected(1)) {
                        updateDispatch(SET_LOADING, true);
                        updatePrescriptionOnly(true);
                        ////change prescriptionOnly to 1 in cart
                        await updateCartAndDB({only_prescription: 1}, addCannabisIDToCartProductsState); //dbcall
                        updateSelectedPersonalShippingIndex(1);
                        await resetBonus(false)
                        updateDispatch(SET_LOADING, false);
                      }


                    }}
                    option={"service_option_2"}
                    disabled={!allowPrescriptionOnly}
                    showRadioSelected={!limitToPrescriptionOnly}
                  />
                </>
              ) : null
            }

          </div>

          <p className="delivery-options-title">{translator.translate(language, "shipping", "layout/living_address")}{" "}</p>

          <ShippingForm language={language} selected={selected} />
        </div>
      </div>
    </div>
  ) : null;
};

export default PersonalAdderssShipping;
