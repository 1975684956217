import * as React from "react";
import TrustpilotBanner from "../../TrustpilotBanner/TrustpilotBanner";
import { tempGetLocale } from "../../../utils/translator";

interface TreatmentsModalProps {
  headerData: HeaderData | null;
  country: string | 'de';
  onLinkclickCallBack?: null | ((args: any) => void);
  getOnClickArgs?: (childCategory: any) => any[];
}

export default function TreatmentsModal({ headerData, country, onLinkclickCallBack = null, getOnClickArgs = (childCategory: any) => [] }: TreatmentsModalProps) {

  const getGetChildCatagory = (childCategory: any) => {
    const settings = JSON.parse(childCategory.settings || null);
    const hasMenuSticker = settings?.categories_menu_sticker;
    return (
      <a
        className="treatment-category-link"
        {...(onLinkclickCallBack
          ? { onClick: () => onLinkclickCallBack(...(getOnClickArgs!(childCategory) as [any])) }
          : { href: childCategory.uri }
        )}
      >
        <span className={`${hasMenuSticker ? "has-menu-sticker" : ""}`}>{childCategory.title}</span>
        <span> &gt;</span>{/* right arrow */}
        {hasMenuSticker && (
          <span className="categories_menu_sticker">
            {/* grern "NEU" in de site */}
            {settings.categories_menu_sticker}
          </span>
        )
        }
      </a>
    )
  }

  return (
    <div className="treatments-modal">
      <div
        className="treatments-container"
        onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
      >
        {headerData?.rootCategoriesPairs && headerData.rootCategoriesPairs.map((rootCategoryPair: any, index: number) => {
          return (
            <div className="root-category-pair" key={index}>
              {rootCategoryPair.map((rootCategory: any) => (
                <div key={rootCategory.title} className={`root-category`}>
                  <h4>{rootCategory.title}</h4>
                  <ul>
                    {rootCategory.children.map((childCategory: any, childIndex: number) => (
                      <li key={childCategory.id + childIndex}>
                        {getGetChildCatagory(childCategory)}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          );
        })}
      </div>
      <TrustpilotBanner locale={tempGetLocale(country)} />
    </div>
  )
}