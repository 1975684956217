import * as STATIC from "./constants";
import { getPlanIndex, paymentImages, resetBonusesInCart } from "./helpers";
import * as http from "../../utils/http";
import { clientConfig } from "../../config";
import { Ref, useCallback, useEffect, useState } from "react";
import { countries } from "./constants"
import { useCartContext } from "../../customHooks/useCart";
import { useSearchParams } from "react-router-dom";
import { SHIPPING_PAGE_VIEW_EVENT } from "../../utils/consts";
import * as SocialMediaTracker from "../../PixelTrackers/SocialMediaTracker"

type useShippingProps = {
  country: string;
  language: string;
  personalShippingFormAttributes: any;
  packstationShippingFormAttributes: any;
  billingShippingFormAttributes: any;
  destinationCountry: string;
  setDestinationCountry: Function
};

/**
 * a colleciton of states and functions for Shipping component
 */

const useShipping = ({
  country,
  destinationCountry,
  setDestinationCountry,
  language,
  personalShippingFormAttributes,
  packstationShippingFormAttributes,
  billingShippingFormAttributes,
}: useShippingProps) => {


  let personalAddress: any;
  let packstationAddress: any;

  const { saveCart, updateCartAndDB, updateCart, addProduct } = useCartContext();
  const [searchParams] = useSearchParams();

  // ------------------------- Catalog related states -------------------------------------------

  const [mention, setMention] = useState("");
  const [catalog, setCatalog] = useState(null);
  const [productCatalog, setProductCatalog] = useState(null);
  const [isTreatmentOTC, setIsTreatmentOTC] = useState(false);
  const [autoBonusValue, setAutoBonusValue] = useState(0);
  const [codSupport, setCodSupport] = useState(true);
  const [originlCodSupport, setOriginalCodSupport] = useState(true);
  const [wireSupport, setWireSupport] = useState(true);
  const [isTestKit, setIsTestKit] = useState(false);
  const [isAutoBonus, setIsAutoBonus] = useState(false);
  const [allowEPrescriptionOnlyFeature, setAllowEPrescriptionOnlyFeature] = useState(false);
  const [selfPickupPharmacy, setSelfPickupPharmacy] = useState(null); //In self pick up flow, we show only this pharmacy

  // ------------------------- // DigitalCannabisIDWidget states  -------------------------------------------
  type CannabisIDState = {
    showCannabisIDWidget: boolean;
    isCannabisIDAddedToOrder: boolean;
    personalIDNumber: string;
    errorMsg: string;
    widgetRef: Ref<HTMLDivElement> | null;
    cost: number;
    cannabisIdProductItem: ProductItem | null;
    cannabisIdCartView: Cart[] | null;
  };

  const [cannabisIDState, setCannabisIDState] = useState<CannabisIDState>({
    showCannabisIDWidget: false,
    isCannabisIDAddedToOrder: false, //If added to cart or not
    personalIDNumber: "", // The personal ID for digital cannabis ID
    errorMsg: "", //The error msg of the input incase validation failed
    widgetRef: null,
    cost: 0, // cost from the serverside (variables table)
    cannabisIdProductItem: null,
    cannabisIdCartView: []
  });


  // ----------------------------- Precaution (Identity check state) ------------------------------------------- 

  type IdentityCheckState = {
    shouldForceCheck: boolean;
    isPrecautionToggleEnabled: boolean;
    precautionLevel: string;
  }
  
  const [identityCheckState, setIdentityCheckState] = useState<IdentityCheckState>({
    shouldForceCheck: false,
    isPrecautionToggleEnabled: false,
    precautionLevel: "info",
  })
  

  // ----------------------------- Form related states -------------------------------------------

  const [prescriptionOnly, setPrescriptionOnly] = useState(false);
  const [selectedDeliveryMethodIndex, setSelectedDeliveryMethodIndex] = useState(1);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(0);
  const [selectedPersonalShippingIndex, setSelectedPersonalShippingIndex] = useState(0);
  const [limitToPrescriptionOnly, setLimitToPrescriptionOnly] = useState(false);
  const [forceCountry, setForceCountry] = useState(false);
  const [selectedDeliveryMethodID, setSelectedDeliveryMethodID] = useState<number | null>(null);
  const [descriptions, setDescriptions] = useState<any>({});
  const [useAddressAsBillingToggle, setUseAddressAsBillingToggle] = useState(true);
  const [shippingMethodsByPriority, setShippingMethodsByPriority] = useState<Record<string, any>>({});
  const [minimalShippingMethodPriorty, setMinimalShippingMethodPriorty] = useState<number | null>(null);




  // -------------------------------- Misc ---------------------------------------------------

  const [error, setError] = useState<any>(false);
  const [loading, setLoading] = useState(true);
  const [cartID, setCartID] = useState(null);
  const [OTCAddonsProducts, setOtcAddonsProducts] = useState([]);
  const [rxProductFinalPrice, setRxProductFinalPrice] = useState<number>(0); //rx price after all kind if discounts
  const [adyenDropInMethodID, setAdyenDropInMethodID] = useState(null);
  const [formattedCountries, setFormattedCountries] = useState(null);
  const [showAddressValidation, setShowAddressValidation] = useState("");
  const [currencyRates, setCurrencyRates] = useState<Record<string, any> | null>(null);
  const [currencySign, setCurrencySign] = useState("€");
  const [customerData, setCustomerData] = useState<any>(null);
  const [DBcart, setDBcart] = useState<any>(null)
  const [currencyTransformer, setCurrencyTransformer] = useState<any>(() => () => { });
  const [preSelectedExternalPharmacy,setPreSelectedExternalPharmacy] = useState<ExternalPharmacy | null>(null)


  // ---------------------------------- Subscriptions and coupons -----------------------------

  //boolean state that says if the current treatment is a subscription
  const [isSubscriptionFlow, setIsSubscriptionFlow] = useState(false);
  const [orderWindowDetails, setOrderWindowDetails] = useState({});
  const [subscriptionFtd, setSubscriptionFtd] = useState(undefined);
  const [subscriptionDiscount, setSubscriptionDiscount] = useState(0);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [otcCouponDiscount, setOtcCouponDiscount] = useState(0);
  const [showCouponInput, setShowCouponInput] = useState(true);
  const [showCouponConfirmed, setShowCouponConfirmed] = useState(false);
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(null);
  const [showSubscriptionPlans, setShowSubscriptionPlans] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [discountData, setDiscountData] = useState({});
  const [replacableSubscriptionPlans, setReplacableSubscriptionPlans] = useState(null)
  const [isDataFullyLoaded, setIsDataFullyLoaded] = useState(false);




  //--------------------------- helper functions ------------------------------------


  const getCurrencyTransformFunction = (toCountry: string, rate: number): Function => {

    let fromCountry = "";
    if (toCountry === "ch") {
      fromCountry = "de"
    } else {
      fromCountry = "ch"
    }

    if (fromCountry === 'de' && toCountry === 'ch') {
      return (currentPrice: number) => currentPrice * rate;

    } else if (fromCountry === 'ch' && toCountry === 'de') {
      return (currentPrice: number) => currentPrice / rate;

    } else {
      return (currentPrice: number) => currentPrice;
    }
  }

  const changeDefaultCountryByIp = (country: string, ipCountry: string) => {
    let destinationCountry = '';
    if (country === countries.Deutschland && ipCountry) {
      destinationCountry = ipCountry.toLowerCase() === countries.Switzerland ? countries.Switzerland : country
      setDestinationCountry(destinationCountry);
    }
  }

  const handleSubscriptionPlans = (shouldMultiply: boolean, currencySign: string) => {
    let handledSubscriptionPlans = [];

    if(DBcart?.funnel_version === "funnel_new_multiple") { //TODO: On multiple RX project, we need to make this disaster work somehow (perhaps consider refactor shipping page)
      return []
    }


    if (replacableSubscriptionPlans) {
      let copyReplacablePlans = JSON.parse(JSON.stringify(replacableSubscriptionPlans));
      for (const plan of copyReplacablePlans) {
        if (shouldMultiply && currencyRates) {
          plan.discount = (plan.discount * currencyRates.ch.rate).toFixed(2)
        }
        plan.checkout_title = plan.checkout_title.replace(':discount', plan.discount).replace(':currency', currencySign)
        handledSubscriptionPlans.push(plan)
      }
      return handledSubscriptionPlans
    }
  }

  //resets bonus
  useEffect(() => {
    if(!cannabisIDState.isCannabisIDAddedToOrder && DBcart){

      const deeplinkCoupon = sessionStorage.getItem('cpn');
      const resetBonus = async () =>{
        // await updateBonusDBCart(null, null, false);
        const { cartView } = await resetBonusesInCart();
        updateCart({ ...cartView?.cart});
        updateDispatch('show_coupon_confirmed', false);
        updateDispatch('show_coupon_discount' ,true);
        updateDispatch('show_coupon_input' ,true);
        updateDispatch('discount_data',0);
        updateDispatch('coupon', 0)
      }

      if (!deeplinkCoupon) {
        resetBonus()
      }
    }
  }, [cannabisIDState.isCannabisIDAddedToOrder, DBcart, prescriptionOnly])

  //handle the subscription plans once onload, other handles at country change
  useEffect(() => {
    
      const productItemPlans = handleSubscriptionPlans(destinationCountry === "ch" ? true : false, destinationCountry === 'ch' ? 'CHF' : '€')
      setOrderWindowDetails((current) => ({
        ...current,
        subscriptionPlans: !DBcart?.is_e_recipe ? productItemPlans : null
      }));
    
  }, [replacableSubscriptionPlans]);


  //this useEffect listens to changes in destinationCountry, and changes prices & currencySign accordingly
  useEffect(() => {
    let changedSubscriptionPlans: any;

    if (currencyRates && formattedCountries) {
      const destinationCurrencySign = currencyRates[destinationCountry].currency_sign;
      const currentCurrencyTransformer = getCurrencyTransformFunction(destinationCountry, currencyRates.ch.rate);
      setCurrencyTransformer(() => currentCurrencyTransformer);
      changedSubscriptionPlans = handleSubscriptionPlans(destinationCountry === "ch", destinationCurrencySign);
      setCurrencySign(destinationCurrencySign);
      setDescriptions((current: any) => {
        let newValue
        if (destinationCountry == "ch") {
          newValue = current.serviceOptions["service_option_1"].value.replace('48', '72');
        } else {
          newValue = current.serviceOptions["service_option_1"].value.replace('72', '48');
        }
        return {
          ...current,
          serviceOptions: {
            ...current.serviceOptions,
            service_option_1: {
              ...current.serviceOptions["service_option_1"],
              value: newValue
            }
          }
        };
      });
      if (destinationCountry.toUpperCase() == "CH") {
        setCodSupport(false);
      } else {
        setCodSupport(originlCodSupport);
      }
      setOrderWindowDetails((current: any) => {
        return {
          ...current,
          subscriptionPlans: !DBcart?.is_e_recipe ? changedSubscriptionPlans : null
        };
      });
    }


  }, [destinationCountry, currencyRates, formattedCountries])


  // --------------------------- dispatch function --------------------------------------

  const updateDispatch = (key: string, value: any) => {
    switch (key) {

      case "is_auto_bonus":
        setIsAutoBonus(value);
        break;

      case "auto_bonus_value":
        setAutoBonusValue((old: any) => value);
        break;

      case "coupon":
        setCouponDiscount(value);
        break;

      case "otc_coupon":
        setOtcCouponDiscount(value);
        break;

      case "subscription":
        setSubscriptionDiscount(value);
        break;

      case "subscription_modal":
        setShowSubscriptionModal(value);
        break;

      case "show_subscription_plans":
        setShowSubscriptionPlans(value);
        break;

      case "selected_plan_index":
        setSelectedPlanIndex(value);
        break;

      case "show_coupon_confirmed":
        setShowCouponConfirmed(value);
        break;

      case "show_coupon_input":
        setShowCouponInput(value);
        break;

      case "discount_data":
        setDiscountData(value);
        break;

      case "is_subscription_flow":
        setIsSubscriptionFlow(value);
        break;

      case "payment_method_index":
        setSelectedPaymentMethod(value);
        break;

      case "use_address_as_billing":
        setUseAddressAsBillingToggle(value);
        break;

      case "set_loading":
        setLoading(value);
    }
  };

  function onDeliveryMethodChange(methodIndex: number, methodID: number) {
    setSelectedDeliveryMethodIndex(methodIndex);
    setSelectedDeliveryMethodID(methodID);
  }

  function isMethodSelected(selectedMethod: number, methodIndex: number) {
    return methodIndex === selectedMethod;
  }

  function getMinimalShippingMethodPriorty(shippingMethodsByPriority: Record<string, any>) {
    let minPriority = Infinity;
    Object.keys(shippingMethodsByPriority).forEach((priorty) => {
      if (Number(priorty) < minPriority) {
        minPriority = Number(priorty);
      }
    })

    return minPriority

  }

  //main function to set order window
  const updateOrderWindow = (DBcartForLocalScope: any, responseData: any) => {
    let productFeatures = responseData.productFeatures;
    let catalogSettings = responseData.catalogSettings;

    if (productFeatures.product_item_plans && DBcartForLocalScope.subscription_plan_id) {
      let planIndex = getPlanIndex(
        productFeatures.product_item_plans,
        DBcartForLocalScope.subscription_plan_id
      );

      setSelectedPlanIndex(planIndex !== null ? planIndex : null);
      setShowSubscriptionPlans(planIndex !== null ? true : false);
    }

    if (DBcartForLocalScope.auto_bonus) {
      setAutoBonusValue((oldVal) => DBcartForLocalScope.bonus_value);
    }

    setOrderWindowDetails((oldVal: any) => ({
      ...oldVal,
      itemImageUrl: clientConfig.IMAGES_URL + productFeatures.imageSuffix,
      itemTitle: productFeatures.title,
      itemQuantity: productFeatures.quantity,
      treatmentFee: DBcartForLocalScope.treatment_fee,
      Price: productFeatures.item_price,
      serviceFee: productFeatures.service_fee,
      isSubsidized: productFeatures.is_subsidized,
      shippingFee: JSON.parse(responseData.formattedCountries[destinationCountry].settings).shipping_fee,
      subscriptionPlans: DBcartForLocalScope.is_e_recipe ? productFeatures.product_item_plans : null,
      cartID: DBcartForLocalScope.id,
      didntChooseSubscriptionInCT: DBcartForLocalScope.subscription_plan_id === null,
      catalogSettings,
    }));
  };
  //main get function for the shipping page data



  const shippingPageGet = () => {
    let newDescriptions = { ...descriptions };


    http
      .getFromServer(`shipping/${country}/${language}?${searchParams.toString()}`, [
        http.MIDDLEWARES.CUSTOMER_ID,
        http.MIDDLEWARES.CART_ORDER,
      ])
      .then((response: any) => {
        const {
          mention,
          catalog,
          productCatalog,
          productFeatures,
          cart,
          catalogSettings,
          shippingOptions,
          paymentOptions,
          disabledPaymentOptions,
          OTCAddonsProducts,
          customerData: responseCustomerData,
          customerAddressData,
          codStockOptions,
          onlinePaymentType,
          formattedCountries,
          currencies,
          shippingMethodsByPriority,
          cannabisIdCardCost,
          cannabisIdProductItem,
          cannabisIdCartView
        } = response.data;

        if (catalog.disallow_alternative_countries) {
          setForceCountry(true)
        }
        const initMinimalPriority = getMinimalShippingMethodPriorty(shippingMethodsByPriority);
        setMinimalShippingMethodPriorty(initMinimalPriority);


        const DBcartForLocalScope = cart;
        setDBcart(DBcartForLocalScope)

        setCannabisIDState((prevState) => ({
          ...prevState,
          showCannabisIDWidget: catalog.content_type === "cannabis" ? true : false,
          cost: Number(cannabisIdCardCost),
          cannabisIdProductItem: cannabisIdProductItem,
          cannabisIdCartView: cannabisIdCartView,
        }));


        if(catalog.settings){
          const settings = JSON.parse(catalog.settings);
          setIdentityCheckState((prevState) => ({
            ...prevState,
            shouldForceCheck: settings.force_shipping_identity_check
          }))
        } 

        setPreSelectedExternalPharmacy(response.data.preSelectedExternalPharmacy)
        setAllowEPrescriptionOnlyFeature(response.data.allowEPrescriptionOnlyFeature);
        setMention(mention);
        setCatalog(catalog);
        setSelfPickupPharmacy(response.data.selfPickupPharmacy)
        setProductCatalog(productCatalog);
        setAdyenDropInMethodID(onlinePaymentType.id);
        setCurrencyRates(currencies);
        setShippingMethodsByPriority(shippingMethodsByPriority);
        setSelectedDeliveryMethodID(shippingMethodsByPriority[initMinimalPriority].id);
        setFormattedCountries(formattedCountries);
        setOtcAddonsProducts(OTCAddonsProducts);
        setCustomerData(responseCustomerData);
        setCurrencySign((current: string) => currencies[destinationCountry].currency_sign);
        setCurrencyTransformer(() => getCurrencyTransformFunction(country, currencies.ch.rate));
        const isFr = country.toLowerCase() === "fr";
        const shouldLimitToPrescriptionOnly = (DBcartForLocalScope.allow_only_prescription || isFr) && !DBcartForLocalScope.product_in_stock;
        setLimitToPrescriptionOnly(shouldLimitToPrescriptionOnly);
        if (shouldLimitToPrescriptionOnly) {
          setPrescriptionOnly(true);
        }
        const subscriptionDiscountValue = DBcartForLocalScope.applied_subscription_discount;
        let discountLogic = catalogSettings.subscription_discount_logic;
        let disableCod = false;
        let disableWire = false;


        if (disabledPaymentOptions) {
          disabledPaymentOptions.forEach((rule: any) => {
            if (rule.code === STATIC.COD) {
              disableCod = true;
            }

            if (rule.code === STATIC.WIRE) {
              disableWire = true;
            }
          });
        }

        if (codStockOptions.length === 0 || disableCod) {
          setCodSupport(false);
          setOriginalCodSupport(false);
        }

        if (disableWire) {
          setWireSupport(false);
        }

        setLoading(false);
        setCartID(DBcartForLocalScope.id);
        setIsAutoBonus(DBcartForLocalScope.auto_bonus);
        if (DBcartForLocalScope.subscription_plan_id > 0) {
          setIsSubscriptionFlow(true);
        }

        //If coupon applied (the discount didn't come from auto_bonus)
        if (DBcartForLocalScope.bonus_value && DBcartForLocalScope.bonus_id && !DBcartForLocalScope.auto_bonus) {
          setCouponDiscount(DBcartForLocalScope.bonus_value);
          setShowCouponConfirmed(true);
          setShowCouponInput(false);
        }


        // Cannaleo preselected project
        if (cart.funnel_version === "funnel_new_multiple") {
          saveCart({...cart,products: response.data.cartProducts })
          
        
        // Old shipping support 
        } else {

          if (discountLogic === STATIC.SUBS_DISCOUNT_FTD) {
            setSubscriptionFtd(productFeatures.subscription_ftd);
  
            if (subscriptionDiscountValue) {
              setSubscriptionDiscount(subscriptionDiscountValue);
            } else if (productFeatures.subscription_ftd) {
              setSubscriptionDiscount(productFeatures.subscription_ftd);
              // updateBonusDBCart(
              //   DBcartForLocalScope.bonus_id,
              //   DBcartForLocalScope.bonus_value,
              //   DBcartForLocalScope.auto_bonus,
              //   subscriptionFtd
              // );

              updateCartAndDB({
                bonus_id: DBcartForLocalScope.bonus_id,
                bonus_value: DBcartForLocalScope.bonus_value,
                auto_bonus: DBcartForLocalScope.auto_bonus,
                applied_subscription_discount: subscriptionFtd
              })
            }
          } else if (discountLogic === STATIC.SUBS_DISCOUNT_REGULAR) {
            if (subscriptionDiscountValue) {
              setSubscriptionDiscount(subscriptionDiscountValue);
            }
          }
          setIsTestKit(productFeatures.isTestKit);
          setReplacableSubscriptionPlans(productFeatures.product_item_plans);
          updateOrderWindow(DBcartForLocalScope, { catalogSettings, productFeatures, formattedCountries, currencyRates: currencies, destinationCountry });
        }
        newDescriptions.serviceOptions = shippingOptions;
        newDescriptions.paymentOptions = paymentOptions;
        setDescriptions(newDescriptions);
       


        //autofill form:

        personalAddress = customerAddressData?.regular;
        packstationAddress = customerAddressData?.packstation;

        let autofillPersonalAddressDetails = {
          address: personalAddress?.address || "",
          city: personalAddress?.city || "",
          company: personalAddress?.company ? personalAddress?.company : "",
          first_name: personalAddress?.first_name || customerAddressData?.first_name,
          last_name: personalAddress?.last_name || customerAddressData?.last_name,
          zip: personalAddress?.zip || "",
          phone: personalAddress?.phone || "",
        };

        let autofillPackstationDetails = {
          packstation_number: packstationAddress?.packstation_number || "",
          packstation_address: packstationAddress?.packstation_address || "",
          packstation_personal_address: packstationAddress?.address || "",
          packstation_city: packstationAddress?.city || "",
          packstation_personal_first_name: packstationAddress?.first_name || customerAddressData?.first_name,
          packstation_personal_last_name: packstationAddress?.last_name || customerAddressData?.last_name,
          packstation_zip: packstationAddress?.zip || "",
          packstation_personal_phone: packstationAddress?.phone || "",
          packstation_personal_zip: "",
          packstation_personal_city: "",
        };

        //possible CH initiation
        if (!shouldLimitToPrescriptionOnly && !catalog.disallow_alternative_countries) { //ch cant be selected if prescription only or if original funnel country is forced
          changeDefaultCountryByIp(country, DBcartForLocalScope.ip_country_id);
          if (personalAddress && personalAddress.country_id != (countries.France).toUpperCase() && country === countries.Deutschland) {
            setDestinationCountry(personalAddress.country_id.toLowerCase());
          }
        }

        personalShippingFormAttributes.overrideForm(autofillPersonalAddressDetails);
        packstationShippingFormAttributes.overrideForm(autofillPackstationDetails);
        setIsDataFullyLoaded(true);

        SocialMediaTracker.track("pv", SHIPPING_PAGE_VIEW_EVENT, {mention, searchParamas: Object.fromEntries(searchParams.entries())});

      })
      .catch((error) => {
        setLoading(false);
        window.clientLogger.error("error get shipping data", error);
        setError(500);
      });
  };


//This doesn't add in the DB !
  const addCannabisIDToCartProductsState = useCallback((cartView:any) => {
    if (!cartView || !cannabisIDState) {
      return
    }

    if (!cannabisIDState.isCannabisIDAddedToOrder) { 
      return
    }


    addProduct({...cannabisIDState.cannabisIdCartView , item_price: cartView.only_prescription ? cannabisIDState.cost : 0} as CartProductView);
  }, [cannabisIDState])

  return {
    DBcart,
    forceCountry,
    mention,
    catalog,
    productCatalog,
    isOnlyPrescription: prescriptionOnly,
    countryID: country,
    language: language,
    isSubscriptionFlow,
    isTreatmentOTC,
    orderWindowDetails,
    updateDeliveryMethodID: setSelectedDeliveryMethodID,
    selectedDeliveryMethodIndex,
    shippingMethodsByPriority,
    minimalShippingMethodPriorty,
    updatePrescriptionOnly: setPrescriptionOnly,
    pageState: descriptions,
    onDeliveryMethodChange,
    isMethodSelected,
    setPrescriptionOnly,
    selectedDeliveryMethodID,
    selectedPaymentMethod,
    paymentImages,
    onPaymentMethodChange: setSelectedPaymentMethod,
    selectedPersonalShippingIndex,
    updateSelectedPersonalShippingIndex: setSelectedPersonalShippingIndex,
    wireSupport,
    cartID,
    setMention,
    setCatalog,
    setProductCatalog,
    setCartID,
    setOrderWindowDetails,
    setDescriptions,
    setIsSubscriptionFlow,
    getShippingPageData: shippingPageGet,
    isTestKit,
    setIsTestKit,
    limitToPrescriptionOnly,
    setLimitToPrescriptionOnly,
    subscriptionFtd,
    setSubscriptionFtd,
    subscriptionDiscount,
    setSubscriptionDiscount,
    OTCAddonsProducts,
    rxProductFinalPrice,
    setRxProductFinalPrice,
    setOtcAddonsProducts,
    loading,
    setLoading,
    isAutoBonus,
    setIsAutoBonus,
    couponDiscount,
    setCouponDiscount,
    otcCouponDiscount,
    setOtcCouponDiscount,
    formattedCountries,
    replacableSubscriptionPlans,
    setReplacableSubscriptionPlans,
    showCouponInput,
    setShowCouponInput,
    showAddressValidation,
    setShowAddressValidation,
    showCouponConfirmed,
    setShowCouponConfirmed,
    selectedPlanIndex,
    setSelectedPlanIndex,
    showSubscriptionPlans,
    setShowSubscriptionPlans,
    currencyRates,
    autoBonusValue,
    setAutoBonusValue,
    codSupport,
    setCodSupport,
    originlCodSupport,
    useAddressAsBillingToggle,
    setUseAddressAsBillingToggle,
    showSubscriptionModal,
    setShowSubscriptionModal,
    updateDispatch,
    discountData,
    error,
    setError,
    customerData,
    setIsTreatmentOTC,
    adyenDropInMethodID,
    handleSubscriptionPlans,
    currencySign,
    setCurrencySign,
    currencyTransformer,
    allowEPrescriptionOnlyFeature,
    selfPickupPharmacy,
    cannabisIDState,
    setCannabisIDState,
    identityCheckState,
    setIdentityCheckState,
    preSelectedExternalPharmacy,
    isDataFullyLoaded,
    addCannabisIDToCartProductsState
  };
};

export default useShipping;
