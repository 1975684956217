import React from 'react'
import './ThanksForFeedbackWidget.css'
import { IoMdCheckmark } from 'react-icons/io'
import * as translator from "../../../../utils/translator"
import { MainTracker } from '../../../../PixelTrackers/MainTracker'


type props = {
  language: string,
  rating: number,
  orderData: any
}

const ThanksForFeedback = ({language, rating, orderData}: props) => {
  const title = translator.translate(language, "my_account", 'MyOrders/details/thankYouTitle');
  const goodReviewThankYouMassage = translator.translate(language, "my_account", 'MyOrders/details/goodFeedbackThankYouMassage');
  const badReviewthankYouMassage = translator.translate(language, "my_account", 'MyOrders/details/badFeedbackthankYouMassage');
  const trustPilot = translator.translate(language, "my_account", 'MyOrders/details/trustPilot');
  let trustPilotLink;
  if(language === "de"){
    trustPilotLink = "https://de.trustpilot.com/review/doktorabc.com"
  }
  else if(language === "fr"){
    trustPilotLink = "https://fr.trustpilot.com/review/doktorabc.com"
  }

  const onTrustPilotClick = () => {
    MainTracker.track('custom', 'Click to submit trustpilot feedback', {hashID:orderData.hashID, mixpanelCategoryCatalog: orderData.mention, orderAmount:orderData.prices.total});
  }

  return (
    <div className='thanks-for-feedback'>
        <h2>{title}</h2>
        <div className='green-checkmark'>
          <IoMdCheckmark className='checkmark2' size={60}/>
          <IoMdCheckmark className='checkmark1' size={60}/>
        </div>
        <div className='thanks-message'>
          <p>{rating >= 4 ? goodReviewThankYouMassage : badReviewthankYouMassage}</p>
          {rating >= 4 && <a href={trustPilotLink} onClick={onTrustPilotClick}>{trustPilot}</a>}
        </div>
    </div>
  )
}

export default ThanksForFeedback